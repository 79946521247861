.error-page {
    background: #000;
    color: #fff;
    height: 100%;
    display: flex;
    justify-content: center;
}

#error-container {
    text-align: center;
    position: relative;
}

#error-container > h1 {
    margin-top: 100%;
}

#error-image {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}