html,
body,
#root {
    height: 100%;
}

body {
    margin: 0;
    
    font-family: 'Helvetica Neue';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#navbar {
    text-align: center;
}

#navbar ul {
    position: fixed;
    top: 0;
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

#navbar li {
    display: inline;
    user-select: none;
}

#navbar li a {
    display: inline-block;
    padding: 10px;
    margin: 10px;
    margin-top: 30px;
    border-radius: 6px;
    font-family: monospace;
    font-size: 18px;
    color: #000;
    text-decoration: none;
}

#navbar li a:hover {
    color: #fff;
    background-color: #000;
}

#flying-head {
    pointer-events: none;
}

#flying-head > img {
    position: fixed;
    width: 100px;
    height: 100px;
    object-fit: cover;
	top: 30vh;
	left: -50px;
    animation: fly 10s linear infinite;
    transform: translate(-50%, -50%);
    border: 1px solid #000;
    pointer-events: none;
}

@keyframes fly { /* TODO */
	98.001%, 0% {
		top: 10vh;
        left: calc(100vw + 50px)
	}

	15% {
		top: -50px;
        left: 50vw;
	}

	15.001%, 18% {
		top: calc(100vh + 50px);
        left: 30vw;
	}

	40% {
		top: 10vh;
        left: calc(100vw + 50px)
	}

	40.001%, 43% {
		top: 10vh;
        left: calc(100vw + 50px)
	}

	65% {
		top: 10vh;
        left: calc(100vw + 50px)
	}

	65.001%, 68% {
		top: 10vh;
        left: calc(100vw + 50px)
	}

	95% {
		top: 10vh;
        left: calc(100vw + 50px)
	}
}