.landing {
    overflow: hidden;
    height: 100%;
}

#image-row {
    width: 100%;
    height: 50%;
}

#image-row > img {
    display: block;
    max-width: 450px;
    height: auto;
    float: right;
    margin: 200px;
    margin-top: 125px;
    margin-bottom: 0px;
}

#title-row {
    display: block;
}

#title-row > h3 {
    font-family: monospace, monospace;
    font-weight: 300;
    margin-left: 50px;
    margin-bottom: 0px;
    bottom: 2px;
}

#list-row {
    height: 90%;
}

#list-row > ul {
    display: block;
    margin: 0px;
    margin-left: 75px;
    list-style-type: none;
    font-size: 70px;
    font-weight: 800;
    text-transform: uppercase;
}

#list-row > ul > li {
    margin-bottom: -30px;
    white-space: nowrap;
}